<style lang='scss' scoped>
img {
  width: 100%;
  height: 100%;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: PingFang SC,monaco,Helvetica,Microsoft Yahei,Arial,sans-serif;
  font-size: 0.24rem;
}
button {
  appearance: none;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
}
.van-popup {
  border-radius: 0.24rem 0.24rem 0 0;
}
.style-d-wrapper {
  display: flex;
  align-items: flex-end;
  width: 7.5rem;
  height: 100%;
  margin: 0 auto;
  border-radius: 0.24rem 0.24rem 0 0;
}
.style-d-box {
  width: 100%;
  padding: 0.24rem 0.32rem;
  border-radius: 0.24rem 0.24rem 0 0;
  background: #fff;
}
.style-d-header {
  width: 100%;
  height: 0.48rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
  span {
    font-size: 0.32rem;
    line-height: 0.48rem;
    font-weight: 500;
  }
}
.close-icon {
  width: 0.32rem;
  height: 0.32rem;
}
.style-d-card {
  margin-bottom: 0.4rem;
  border-radius: 0.18rem;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
}

.sub-title {
  line-height: 0.4rem;
  font-size: 0.28rem;
  font-weight: 500;
  margin-bottom: 0.22rem;
}
.style-list {
  display: flex;
  overflow-x: auto;
}
.style-shape {
  margin: 0 0.22rem 0.34rem 0;
  border-radius: 0.04rem;
  border: 0.02rem solid #CCCCCC;
}
.active-style {
  border-color: #1472FF;
}
.upload-avatar {
  margin-bottom: 0.44rem;
}
.upload-btn {
  width: 1.2rem;
  height: 1.2rem;
  background: transparent;
  border: 0.01rem solid #f5f5f5;
  position: relative;
}
.close-pic {
  position: absolute;
  top: -0.16rem;
  right: -0.16rem;
  background: transparent;
}
.footer-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.24rem;
  button {
    width: 3.28rem;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 0.4rem;
    text-align: center;
  }
}
.btn-1 {
  border: 0.01rem solid #CCCCCC;
  background: #FFFFFF;
  color: #333333;
}
.btn-2 {
  background: #1472FF;
  color: #fff;
}
</style>

<template>
  <van-popup v-model="showStyleDialog" position="bottom">
    <div class="style-d-wrapper">

      <div class="style-d-box">

        <div class="style-d-header">
          <span>名片样式及头像设置</span>
          <div class="close-icon" @click="showStyleDialog = false">
            <img :src="require('@/assets/mine/close.svg')" alt="close">
          </div>
        </div>

        <div class="style-d-card">
          <card-style :data="cardInfo" :cardType="cardStyleType" :showArrow="0"></card-style>
        </div>

        <p class="sub-title">选择名片样式</p>
        <div class="style-list">
          <div 
            class="style-shape" 
            v-for="index in 5" :key="index"
            :class="`${index == cardStyleType ? 'active-style' : ''}`"
            :data-type="index"
            @click="onSwitchStyle(index)">
            <style-case :index="index"></style-case>
          </div>
        </div>
        <p class="sub-title">选择名片头像</p>
        <div class="upload-avatar">
          <div class="upload-btn" @click="onCheckImg()" v-if="!avatarUrl">
            <img :src="require('@/assets/mine/add.svg')" alt="add">
          </div>
          <div class="upload-btn" v-if="avatarUrl">
            <img :src="avatarUrl" alt="avatar">
            <button class="close-pic" @click="onDeleteAvatar()">
              <img :src="require('@/assets/mine/close2.svg')" alt="close">
            </button>
          </div>
        </div>
        <div class="footer-line">
          <button class="btn-1" @click="showStyleDialog = false">取消</button>
          <button class="btn-2" @click="onSaveCard()">保存</button>
        </div>

      </div>
    </div>
  </van-popup>
</template>

<script>
import styleCase from './styleCase'
import cardStyle from './cardStyle'

export default {
  name: 'styleSetting',
  components: {
    styleCase, cardStyle
  },
  data() {
    return {
      showStyleDialog: false,
      cardInfo: {
        url: '',
        name: '',
        position: '',
        phone: '',
        email: '',
        address: '',
      },
      cardStyleType: 1, // 名片样式类型

      avatarUrl: '',
    }
  },
  methods: {
    trigger(d) {
      if (d) {
        this.cardInfo = {
          url: d.pic_url || '',
          name: d.user_name || '',
          position: d.title || '',
          phone: d.phone || '',
          email: d.email || '',
          address: d.address || '',
        }
        this.cardStyleType = d.card_style_type || 1
        this.avatarUrl = d.pic_url || ''
      }
      else {
        this.cardInfo = {
          url: '',
          name: '销售名字',
          position: '职位',
          phone: '134XXXXYYYY',
          email: '',
          address: '深圳市南山区科兴科学园A2单元6楼/8楼/9楼/12楼',
        }
        this.cardStyleType = 1
      }
      this.showStyleDialog = true
    },
    onSwitchStyle(index) {
      this.cardStyleType = index
    },
    onCheckImg() {
      this.$emit('checkAvatar')
    },
    onUploadImg(url) {
      if (url) {
        this.cardInfo.url = url
        this.avatarUrl = url
      }
    },
    onDeleteAvatar() {
      this.avatarUrl = ''
    },
    onSaveCard() {
      if (!this.avatarUrl) {
        this.$toast("请上传名片照片")
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '保存中...',
      })
      const p = {
        card_style_type: this.cardStyleType,
        pic_url: this.avatarUrl
      }
      this.$ajax("save_card_style", p).then(
        res => {
          this.$toast.clear()
          if (res.data && res.data.code == 0) {
            this.$toast.success('保存成功')
            this.showStyleDialog = false
          }
          else {
            this.$toast.fail('保存失败')
            console.error(res)
          }
        }
      ).catch(
        err => {
          this.$toast.clear()
          this.$toast.fail('保存失败')
          console.error(err)
        }
      )
    }
  }
}
</script>