<style lang='scss' scoped>
img {
  width: 100%;
  height: 100%;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: PingFang SC,monaco,Helvetica,Microsoft Yahei,Arial,sans-serif;
  font-size: 0.24rem;
}
button {
  appearance: none;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
}
.edit-card {
  background: #F5F5F5;
}
.edit-area {
  padding: 0.22rem 0.34rem 0.32rem;
}
.first-line {
  height: 0.96rem;
  background: #fff;
  padding: 0 0.32rem;
  border-radius: 0.16rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.32rem;
  span {
    font-size: 0.28rem;
  }
}
.right-icon {
  display: flex;
  align-items: center;
  .icon-card {
    width: 0.48rem;
    height: 0.48rem;
    margin-right: 0.08rem;
  }
  .icon-arrow {
    width: 0.32rem;
    height: 0.32rem;
  }
}
.basic-info {
  background: #fff;
  padding: 0 0.32rem;
  border-radius: 0.16rem;
  margin-bottom: 0.32rem;
}
.basic-line {
  padding: 0.28rem 0;
  display: flex;
  border-bottom: 0.01rem solid #ebebeb;
}
.line-title {
  width: 1.12rem;
  line-height: 0.4rem;
  color: #666666;
  font-size: 0.28rem;
  flex-shrink: 0;
  span {
    color: #FF4747;
  }
}
.line-content {
  color: #333333;
  flex-grow: 1;
  display: flex;
}
.text-input {
  width: 100%;
  padding: 0;
  line-height: 0.4rem;
  font-size: 0.28rem;
  border: none;
}
.text-area {
  width: 100%;
  height: inherit;
  padding: 0;
  line-height: 0.4rem;
  border: none;
  resize: none;
  font-size: 0.28rem;
}
.module-area {
  padding: 0.32rem 0;
  background: #fff;
  margin-bottom: 0.32rem;
  border-radius: 0.16rem;
}
.area-title {
  font-size: 0.32rem;
  font-weight: 500;
  line-height: 0.48rem;
  padding-left: 0.32rem;
  display: flex;
  i {
    color: #999999;
    font-size: 0.28rem;
    font-style: normal;
    font-weight: normal;
    margin-left: 0.08rem;
  }
}
.area-content {
  padding: 0.4rem 0.32rem 0;
  height: 4rem;
  position: relative;
  .textarea-wrapper {
    width: 100%;
    height: 100%;
    padding-bottom: 0.4rem;
  }
  .num-calc {
    position: absolute;
    bottom: 0;
    right: 0.32rem;
    color: #9E9E9E;
    background: #fff;
    padding: 0.06rem;
  }
}
.pic-area {
  padding: 0.4rem 0 0 0.32rem;
  display: flex;
  flex-wrap: wrap;
}
.pic-item {
  width: 1.94rem;
  height: 1.94rem;
  margin: 0 0.2rem 0.2rem 0;
  border-radius: 0.08rem;
  background: #e0e0e0;
  position: relative;
  .close-pic {
    position: absolute;
    top: -0.08rem;
    right: -0.08rem;
    width: 0.32rem;
    height: 0.32rem;
    background: transparent;
  }
}
.loading-area {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0009;
}
.upload-pic {
  width: 1.94rem;
  height: 1.94rem;
  border-radius: 0.08rem;
}
.footer-line {
  padding-bottom: 0.82rem;
  width: 7.5rem;
  text-align: center;
  background: transparent;
}
.save-btn {
  width: 6.86rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  background: #1472FF;
  border-radius: 0.4rem;
  font-size: 0.28rem;
  color: #FFFFFF;
}
</style>

<template>
  <div class="edit-card un_select" v-show="showMain">

    <style-setting ref="styleSetting" @checkAvatar="checkAvatar"></style-setting>

    <div class="edit-area">

      <div class="first-line">
        <span>名片样式及名片照设置</span>
        <div class="right-icon" @click="startStyleSetting()">
          <img class="icon-card" :src="require('@/assets/mine/card.svg')" alt="card">
          <img class="icon-arrow" :src="require('@/assets/mine/ic_arrow_left_big.png')" alt="arrow">
        </div>
      </div>

      <div class="basic-info">
        <div class="basic-line">
          <div class="line-title">姓名<span>*</span></div>
          <div class="line-content">
            <input type="text" class="text-input" placeholder="请输入" v-model.trim="name">
          </div>
        </div>
        <div class="basic-line">
          <div class="line-title">手机<span>*</span></div>
          <div class="line-content">
            <input type="text" class="text-input" placeholder="请输入" v-model.trim="phone">
          </div>
        </div>
        <div class="basic-line">
          <div class="line-title">职位<span>*</span></div>
          <div class="line-content">
            <input type="text" class="text-input" placeholder="请输入" v-model.trim="position">
          </div>
        </div>
        <div class="basic-line">
          <div class="line-title">邮箱</div>
          <div class="line-content">
            <input type="text" class="text-input" placeholder="请输入" v-model.trim="email">
          </div>
        </div>
        <div class="basic-line" style="border:none">
          <div class="line-title">地址</div>
          <div class="line-content">
            <textarea
              cols="30"
              rows="2"
              class="text-area"
              placeholder="请输入"
              v-model.trim="address">
            </textarea>
          </div>
        </div>
      </div>

      <div class="module-area">
        <p class="area-title">我的简介</p>
        <div class="area-content">
          <div class="textarea-wrapper">
            <textarea
              cols="30"
              class="text-area"
              placeholder="请填写个人简介"
              v-model.trim="summary"
              :maxlength='1000'>
            </textarea>
          </div>
          <span class="num-calc">{{summary.length}}/1000</span>
        </div>
      </div>

      <div class="module-area">
        <p class="area-title">我的照片<i>({{ picList.length }}/9)</i></p>
        <div class="pic-area">
          <div class="pic-item" v-for="(item, index) in picList" :key="index">
            <img :src="item.pic_url" alt="pic" @click="onPreview(picList, index)">
            <button class="close-pic" @click="onDeletePic(index)" v-if="!item.is_local">
              <img :src="require('@/assets/mine/close2.svg')" alt="close">
            </button>
            <div class="loading-area" v-if="item.is_local">
              <van-loading type="spinner" color="#fff"/>
            </div>
          </div>
          <button class="upload-pic" @click="startUploadPic()" v-if="picList.length < 9">
            <img :src="require('@/assets/mine/add.svg')" alt="add">
          </button>
        </div>
      </div>

    </div>

    <div class="footer-line">
      <button class="save-btn" @click="onSaveCard()">保存</button>
    </div>

    <input
      v-show="false"
      type="file"
      multiple="multiple"
      ref="imageFile"
      accept="image/jpeg,image/png,image/gif,image/bmp"
      :value="inputImgFileValue"
      @change="onImageFileChange">

    <input
      v-show="false"
      type="file"
      ref="imageFile2"
      accept="image/jpeg,image/png,image/gif,image/bmp"
      :value="inputImgFileValue2"
      @change="onImageFileChange2">

  </div>
</template>

<script>
import styleSetting from './styleSetting'
import { uploadImg } from "@/globalWidget/utils/upload.js"
import Vue from 'vue'
import { Loading, ImagePreview } from 'vant'
Vue.use(Loading)

export default {
  name: 'editCard',
  components: {
    styleSetting,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      detailInfo: null,
      showMain: false,

      name: '',
      position: '',
      phone: '',
      email: '',
      address: '',
      summary: '',
      picList: [],

      inputImgFileValue: null,
      uploadType: 1,
      inputImgFileValue2: null,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...',
      })
      this.$ajax("get_card_detail").then(
        res => {
          this.showMain = true
          this.$toast.clear()
          if (res.data && res.data.code == 0) {
            this.handleDetail(res.data.data)
          }
          else {
            this.handleDetail(null)
            this.$toast.fail('网络错误')
            console.error(res)
          }
        }
      ).catch(
        err => {
          this.showMain = true
          this.$toast.clear()
          this.handleDetail(null)
          this.$toast.fail('网络错误')
          console.error(err)
        }
      )
    },
    handleDetail(d) {
      this.detailInfo = d
      if (d) {
        this.name = d.user_name || ''
        this.position = d.title || ''
        this.phone = d.phone || ''
        this.email = d.email || ''
        this.address = d.address || ''
        this.summary = d.introduction || ''
        this.picList = d.pics || []
      }
      else {
        this.name = '销售名字'
        this.position = '职位'
        this.phone = '134XXXXYYYY'
        this.email = ''
        this.address = '深圳市南山区科兴科学园A2单元6楼/8楼/9楼/12楼'
        this.summary = ''
        this.picList = []
      }
    },
    /**
     * 名片样式及头像设置
     */
    startStyleSetting() {
      this.$refs.styleSetting.trigger(this.detailInfo)
    },
    checkAvatar() {
      this.$refs.imageFile2.click()
    },
    /**
     * 删除一张照片
     */
    onDeletePic(index) {
      this.picList.splice(index, 1)
    },
    /**
     * 上传图片
     */
    startUploadPic() {
      this.$refs.imageFile.click()
    },
    onImageFileChange(e) {
      let _this = this
      const fileList = e.target.files
      _this.inputImgFileValue = null
      if (!fileList || fileList.length === 0) {
        return
      }
      let cookie = {}
      document.cookie.split("; ").forEach((e) => {
        cookie[e.split("=")[0]] = e.split("=")[1]
      })
      // 循环上传多张图片
      for (const inputFile of fileList) {
        // file.size : 图片大小(字节数)，上传限制：1M = 1048576 Byte
        if (inputFile.size > 1048576) {
          console.log('file size = ' + inputFile.size + ' Byte')
          _this.$toast('请上传小于1M的图片')
          return
        }

        // file.type：图片类型仅支持JPG,PNG,JPEG格式
        let type = inputFile.type.split('/')[1];
        if(type !== 'jpg' && type !== 'png' && type !== 'jpeg'){
          console.log('file type = ' + type)
          _this.$toast('请上传JPG, JPEG或者PNG格式')
          return
        }

        if (_this.picList.length >= 9) { // 上传图片数量限制
          continue
        }
        let localUrl = ''
        // 上传腾讯云之前先添加一张本地预览图
        if (window.FileReader) {
          let reader = new FileReader()
          reader.readAsDataURL(inputFile)
          reader.onloadend = function(e) {
            localUrl = e.target.result
            _this.picList.push({
              pic_id: 0,
              pic_url: e.target.result,
              is_local: 1,
            })
          }
        }
        uploadImg(
          res => {
            if (res.code === 0) {
              // 上传腾讯云完成，将本地图片切换为腾讯云图片地址
              let arr = _this.picList.filter(ele => (ele.is_local != 1 || ele.pic_url != localUrl) )
              arr.push({
                pic_id: 0,
                pic_url: res.data.access_url,
                is_local: 0
              })
              _this.picList = arr.slice()
            }
            else {
              console.error(res)
              _this.$toast("上传图片失败")
              // 删除本地图片
              _this.picList = _this.picList.filter(ele => (ele.is_local != 1 || ele.pic_url != localUrl) )
            }
          },
          err => {
            console.error(err)
            _this.$toast("上传图片失败")
            // 删除本地图片
            _this.picList = _this.picList.filter(ele => (ele.is_local != 1 || ele.pic_url != localUrl) )
          },
          progress => {
            console.info('progress = ' + progress)
          },
          inputFile,
          cookie.with_app_id
        )
      }
    },
    onImageFileChange2(e) {
      let _this = this
      const fileList = e.target.files
      _this.inputImgFileValue2 = null
      if (!fileList || fileList.length === 0) {
        _this.$refs.styleSetting.onUploadImg()
        return
      }
      const inputFile = fileList[0]
      // file.size : 图片大小(字节数)，限制不能超过2M
      if (inputFile.size > 2086985) {
        console.log('file size = ' + inputFile.size + ' Byte')
        _this.$toast('请上传小于2M的图片')
        _this.$refs.styleSetting.onUploadImg()
        return
      }

      // file.type：图片类型仅支持JPG,PNG,JPEG格式
      let type = inputFile.type.split('/')[1];
      if(type !== 'jpg' && type !== 'png' && type !== 'jpeg'){
        console.log('file type = ' + type)
        _this.$toast('请上传JPG, JPEG或者PNG格式')
        return
      }

      let cookie = {}
      document.cookie.split("; ").forEach((e) => {
        cookie[e.split("=")[0]] = e.split("=")[1]
      })
      _this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '上传图片中...',
      })
      uploadImg(
        res => {
          if (res.code === 0) {
            _this.$toast.clear()
            _this.$refs.styleSetting.onUploadImg(res.data.access_url)
          }
          else {
            console.error(res)
            _this.$toast.clear()
            _this.$toast("上传图片失败")
            _this.$refs.styleSetting.onUploadImg()
          }
        },
        err => {
          console.error(err)
          _this.$toast.clear()
          _this.$toast("上传图片失败")
          _this.$refs.styleSetting.onUploadImg()
        },
        progress => {
          console.info('progress = ' + progress)
        },
        inputFile,
        cookie.with_app_id
      )
    },
    // 点击图片预览
    onPreview(picList, index) {
      ImagePreview({
        images: picList.map(ele => ele.pic_url),
        startPosition: index,
      })
    },
    /**
     * 点击保存名片
     */
    onSaveCard() {
      const obj = this.picList.find(ele => ele.is_local)
      if (obj) {
        this.$toast("正在上传图片中...")
        return
      }
      if (!this.checkParams()) {
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...',
      })
      const p = {
        user_name: this.name,
        title: this.position,
        phone: this.phone,
        email: this.email,
        address: this.address,
        introduction: this.summary,
      }
      if (this.picList.length) {
        p['pic_url'] = this.picList
      }
      else {
        p['pic_url'] = ''
      }
      this.$ajax('save_card', p).then(
        res => {
          if (res.data && res.data.code == 0) {
            this.$toast.success('保存成功')
            this.$router.push('/my_card')
          }
          else {
            this.$toast(res.data?.msg || '保存失败')
            console.error(res)
          }
        }
      ).catch(
        err => {
          this.$toast('保存失败')
          console.error(err)
        }
      )
    },
    checkParams() {
      if (this.name.length == 0) {
        this.$toast('姓名输入不能为空')
        return false
      }
      // 输入0~12个字符
      if (this.calcCharNum(this.name) > 24) {
        this.$toast('姓名输入长度超出，请修改')
        return false
      }
      if (this.position.length == 0) {
        this.$toast('职位输入不能为空')
        return false
      }
      // 输入0~12个字符
      if (this.calcCharNum(this.position) > 24) {
        this.$toast('职位输入长度超出，请修改')
        return false
      }
      if (this.phone.length == 0) {
        this.$toast('手机号输入不能为空')
        return false
      }
      if (this.phone.length > 11) {
        this.$toast('手机号输入长度超出，请修改')
        return false
      }
      const phoneReg = /^[1][0-9]{10}$/
      if (!phoneReg.test(this.phone)) {
        this.$toast('手机号格式有误，请修改')
        return false
      }
      const emailReg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
      if (this.email && !emailReg.test(this.email)) {
        this.$toast('邮箱格式有误，请修改')
        return false
      }
      // 输入栏最多可输入50个汉字
      if (this.calcCharNum(this.address) > 100) {
        this.$toast('地址输入长度超出，请修改')
        return false
      }
      // 内容长度0~1000
      if (this.calcCharNum(this.summary) > 2000) {
        this.$toast('简介内容长度超出，请修改')
        return false
      }
      return true
    },
    /**
     * 计算字符串占位大小
     * 中文 - 2位
     * 其它 - 1位
     */
    calcCharNum(str) {
      let num = 0
      const chineseReg = /^[\u4E00-\u9FA5]+$/
      for (let index = 0; index < str.length; index++) {
        const char = str.charAt(index)
        if (chineseReg.test(char)) {
          num += 2
        }
        else {
          num += 1
        }
      }
      return num
    }
  }
}
</script>
