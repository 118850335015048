<style lang='scss' scoped>
.style-case {
  width: 1.64rem;
  height: 0.88rem;
}
.structure-1 {
  width: 100%;
  height: 100%;
  position: relative;
  .bg-avatar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 0.8rem;
    height: 0.88rem;
    background: #d8d8d8;
  }
}
.user-info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 0.88rem;
  display: flex;
  flex-direction: column;
  .line-1 {
    width: 0.36rem;
    height: 0.12rem;
    margin: 0.14rem 0 0 0.06rem;
    background: #D8D8D8;
    border-radius: 0.06rem;
  }
  .line-2 {
    width: 0.20rem;
    height: 0.08rem;
    margin: 0.04rem 0 0 0.06rem;
    background: #D8D8D8;
    border-radius: 0.04rem;
  }
  .line-3 {
    width: 0.45rem;
    height: 0.08rem;
    margin: 0.24rem 0 0 0.06rem;
    background: #D8D8D8;
    border-radius: 0.04rem;
  }
  .line-4 {
    width: 0.79rem;
    height: 0.13rem;
    margin: 0.06rem 0 0 0.06rem;
    background: #D8D8D8;
    border-radius: 0.07rem;
  }
}
.structure-2 {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .circle {
    position: absolute;
    top: 0.08rem;
    left: 0.12rem;
    width: 0.28rem;
    height: 0.28rem;
    background: #D8D8D8;
    border-radius: 0.14rem;
  }
  .line-1 {
    width: 0.46rem;
    height: 0.1rem;
    margin: 0.12rem 0 0 0.46rem;
    background: #D8D8D8;
    border-radius: 0.05rem;
  }
  .line-2 {
    width: 0.14rem;
    height: 0.07rem;
    margin: 0.04rem 0 0 0.46rem;
    background: #D8D8D8;
    border-radius: 0.03rem;
  }
  .line-3 {
    width: 0.59rem;
    height: 0.08rem;
    margin: 0.24rem 0 0 0.1rem;
    background: #D8D8D8;
    border-radius: 0.04rem;
  }
  .line-4 {
    width: 1.37rem;
    height: 0.08rem;
    margin: 0.06rem 0 0 0.1rem;
    background: #D8D8D8;
    border-radius: 0.04rem;
  }
}
.bg-1 {
  background: url('../../assets/mine/bg-1.png') no-repeat;
  background-size: cover;
}
.bg-2 {
  background: url('../../assets/mine/bg-2.png') no-repeat;
  background-size: cover;
}
.bg-3 {
  background: url('../../assets/mine/bg-3.png') no-repeat;
  background-size: cover;
}
.bg-4 {
  background: url('../../assets/mine/bg-4.png') no-repeat;
  background-size: cover;
}
.bg-5 {
  background: url('../../assets/mine/bg-5.png') no-repeat;
  background-size: cover;
}
</style>

<template>
  <div class="style-case">
    <div class="structure-1" v-if="index == 1">
      <div class="bg-avatar"></div>
      <div class="user-info bg-1">
        <div class="line-1"></div>
        <div class="line-2"></div>
        <div class="line-3"></div>
        <div class="line-4"></div>
      </div>
    </div>
    <div class="structure-2" :class="`bg-${index}`" v-else>
      <div class="circle"></div>
      <div class="line-1"></div>
      <div class="line-2"></div>
      <div class="line-3"></div>
      <div class="line-4"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: [Number, String], // 名片样式类型
      default: 1
    }
  }
}
</script>
